import React, { useEffect, useRef, useState } from 'react';
import { Route, withRouter, useLocation, useHistory } from 'react-router-dom';
import App from '../src/App';
import { LetsBegin } from '../src/pages/begin';
import { TermsAndConditions } from '../src/pages/terms';
import { NoticeOfPrivacy } from '../src/pages/notice';
import { VerifyNumber } from '../src/pages/verify';
import { Error } from '../src/pages/Error';
import { NotFound } from '../src/pages/NotFound';
import { Access } from '../src/pages/AccessDenied';
import authGuardService from '../src/service/authGuardService';
import configFile from './configurationFile'
import { AddAccount } from './pages/Add';
import { AddBank } from './pages/AddBank';
import { AddingAccount } from './pages/AddAccount';
import { AccountAdded } from './pages/AccountAdded';
import { Slides } from './pages/Sliders';
import { AllSet } from './pages/AllSet';
import { Faq } from './pages/Faq';
import { SendingInformation } from './pages/SendingInfo';
import { ProcessEnd } from './pages/ProcessEnd';
import { LastScreen } from './pages/LastScreen';
import TestService from './service/testService';

const AppWrapper = (props: any) => {
	const [colorScheme, setColorScheme] = useState(configFile.theme)
	const [theme, setTheme] = useState('green');
	const [componentTheme, setComponentTheme] = useState('green');

	const permisos: { label: any; }[] = []

	let location = useLocation();

	useEffect(() => {
		onColorSchemeChange(colorScheme);
		onComponentThemeChange('green')
	}, []);

	

	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso = { label: menuItem.label }
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	const onColorSchemeChange = (scheme: string) => {
		changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
		changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
		setColorScheme(scheme);
	}

	const changeStyleSheetUrl = (id: any, value: any, from: any) => {
		const element = (document.getElementById(id) as HTMLInputElement);
		const urlTokens = (element.getAttribute('href') as String).split('/');

		if (from === 1) {           // which function invoked this function - change scheme
			urlTokens[urlTokens.length - 1] = value;
		} else if (from === 2) {       // which function invoked this function - change color
			urlTokens[urlTokens.length - 2] = value;
		}

		const newURL = urlTokens.join('/');

		replaceLink(element, newURL);
	}

	const onMenuThemeChange = (theme: string) => {
		const layoutLink = document.getElementById('layout-css');
		const href = 'assets/layout/css/' + theme + '/layout-' + colorScheme + '.css';

		replaceLink(layoutLink, href);
		setTheme(theme);
	}

	const onComponentThemeChange = (theme: string) => {
		const themeLink = document.getElementById('theme-css');
		const href = 'assets/theme/' + theme + '/theme-' + colorScheme + '.css';
		replaceLink(themeLink, href);
		setComponentTheme(theme);
	}

	const replaceLink = (linkElement: any, href: string, callback?: any) => {
		const id = linkElement.getAttribute('id');
		const cloneLinkElement = linkElement.cloneNode(true);
		cloneLinkElement.setAttribute('href', href);
		cloneLinkElement.setAttribute('id', id + '-clone');
		linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
		cloneLinkElement.addEventListener('load', () => {
			linkElement.remove();
			cloneLinkElement.setAttribute('id', id);

			if (callback) {
				callback();
			}
		});
	}

	const home = () => {
		return <App colorScheme={colorScheme} onColorSchemeChange={onColorSchemeChange}
			componentTheme={componentTheme} onComponentThemeChange={onComponentThemeChange}
			theme={theme} onMenuThemeChange={onMenuThemeChange} />;
	}

	const ValidateHomeLogin = () => {
/* 		if (authGuardService.isLoggednIn()) 
 */		return home()
		/* return <Access colorScheme={colorScheme} />; */
	};

	const ValidateLanding = () => {
		if (configFile.landing) {
			if (authGuardService.isLoggednIn()) return <Slides />
			return <Slides />;
		} else {
			return ValidateLoggin()
		}
	}

	const ValidateLoggin = () => {
		if (authGuardService.isLoggednIn()) return <LetsBegin colorScheme={colorScheme} />
		return <LetsBegin colorScheme={colorScheme} />;
	}

	switch (props.location.pathname) {
		case '/slides':
			return <Route path="/slides" render={() => <Slides colorScheme={colorScheme} state={props.location.state} />} />
		case '/all-set':
			return <Route path="/all-set" render={() => <AllSet colorScheme={colorScheme} state={props.location.state} />} />
		case '/home':
			return <Route path="/Home" render={() => ValidateHomeLogin()} />
		case '/error':
			return <Route path="/error" render={() => <Error colorScheme={colorScheme} state={props.location.state} />} />
		case '/notfound':
			return <Route path="/notfound" render={() => <NotFound colorScheme={colorScheme} state={props.location.state} />} />
		case '/access':
			return <Route path="/access" render={() => <Access colorScheme={colorScheme} state={props.location.state} />} />
		case '/termsAndConditions':
			return <Route path="/termsAndConditions" render={() => <TermsAndConditions colorScheme={colorScheme} state={props.location.state} />} />
		case '/noticeOfPrivacy':
			return <Route path="/noticeOfPrivacy" render={() => <NoticeOfPrivacy colorScheme={colorScheme} state={props.location.state} />} />
		case '/verifyNumber':
			return <Route path="/verifyNumber" render={() => <VerifyNumber colorScheme={colorScheme} state={props.location.state} />} />
		case '/add-account':
			return <Route path="/add-account" render={() => <AddAccount colorScheme={colorScheme} state={props.location.state} />} />
		case '/add-bank':
			return <Route path="/add-bank" render={() => <AddBank colorScheme={colorScheme} state={props.location.state} />} />
		case '/adding-account':
			return <Route path="/adding-account" render={() => <AddingAccount colorScheme={colorScheme} state={props.location.state} />} />
		case '/account-added':
			return <Route path="/account-added" render={() => <AccountAdded colorScheme={colorScheme} state={props.location.state} />} />
		case '/faq':
			return <Route path="/faq" render={() => <Faq colorScheme={colorScheme} state={props.location.state} />} />
		case '/sending':
			return <Route path="/sending" render={() => <SendingInformation colorScheme={colorScheme} state={props.location.state} />} />
		case '/final-step':
			return <Route path="/final-step" render={() => <ProcessEnd colorScheme={colorScheme} state={props.location.state} />} />
		case '/last':
			return <Route path="/last" render={() => <LastScreen colorScheme={colorScheme} state={props.location.state} />} />
		case '/login':
			return ValidateLoggin()
		default:
			return ValidateLanding()
	}
}

export default withRouter(AppWrapper);