import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import loadingAnimation from '../assets/checkList.json';
import authGuardService from '../service/authGuardService';

export const ProcessEnd = (props: any) => {

	const history = useHistory();
	const permisos: { label: any; }[] = []
    const design = authGuardService.getDesign()

	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso = { label: menuItem.label }
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	const checkOptions = {
		loop: false,
		autoplay: true,
		animationData: loadingAnimation
	};

	return (
		<div style={{ background: design.backgroundColor || '#5c39e9', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<div style={{ display: 'flex', justifyContent: 'center', background: design.backgroundColor || '#5c39e9', width: '100%' }}>
				<div style={{ display: 'flex', marginTop: '4rem', marginRight: '2rem', marginLeft: '2rem' }}>
					<div>
						<Lottie options={checkOptions}
							height={'12vh'}
							width={'12vh'}
						/>
					</div>
					<div style={{ marginLeft: '0.5rem', marginBottom: '3rem' }}>
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '24px',
							lineHeight: '32px',
							color: design.textBackgroundColor || '#FFFFFF'
						}}>¡Bien!</p>
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '24px',
							lineHeight: '32px',
							color: design.textBackgroundColor || '#FFF',
							marginTop: -10
						}}>Has finalizado este paso!</p>
					</div>
				</div>

			</div>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#FFF', height: '100%', width: '100%' }}>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '3rem', marginLeft: '2rem' }}>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '17px',
						lineHeight: '24px',
						color: '#212426'
					}}>Estas son las cuentas que enviaste a tu ejecutivo: </p>
				</div>

				<div style={{ display: 'flex', alignItems: 'center', borderRadius: 6, flexDirection: 'column', marginTop: '2rem', minWidth: '24rem' }}>
					{
						props?.state?.cards?.map((card: any) => {
							return (
								<>
									<div style={{ width: '90%', display: 'flex', marginTop: '0.5rem', justifyContent: 'space-between', alignItems: 'center' }} key={card.id}>
										<div>
											<p style={{
												fontFamily: `${design?.typo || 'Roboto'}`,
												fontStyle: 'normal',
												fontWeight: 500,
												fontSize: '16px',
												lineHeight: '19px',
												color: '#212426'
											}}>{card.bank}</p>
										</div>
										<div>
											<p style={{
												fontFamily: `${design?.typo || 'Roboto'}`,
												fontStyle: 'normal',
												fontWeight: 600,
												fontSize: '14px',
												lineHeight: '20px',
												color: '#45474D'
											}}>•••• ••••</p>
										</div>
									</div>
									<Divider />
								</>
							)
						})
					}
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '8vh', width: '85%', maxWidth: '25rem' }}>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '20px',
						color: '#45474D',
					}}>Ahora, espera a que tu ejecutivo de cuenta reciba la información para darte el resultado a tu solicitud de crédito.</p>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '4vh', width: '90%' }}>
					<Button label='Entendido' style={{
						height: '52px',
						background: design.backgroundButtonColor || '#F2C91A',
						boxShadow: '0px 24px 24px -8px rgba(75, 75, 75, 0.25)',
						borderRadius: '12px',
						width: '100%',
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '20px',
						color: design.textButtonColor || '#FFF',
						borderColor: 'transparent'
					}} onClick={() => history.push({
						pathname: '/last',
						state: { cards: props.state.cards }
					})} />
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '2vh', width: '90%', marginBottom: 20 }}>
					<Button label="Agregar más cuentas" icon="pi pi-arrow-left" className="p-button-text" style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '20px',
						color: design.backgroundColor || '#5c39e9',
						background: 'transparent'
					}} onClick={() => history.push('/home')} />
				</div>



			</div>
		</div >
	)
}