import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppMenu from './VerticalMenu/AppMenu';
import { classNames } from 'primereact/utils';
import authGuardService from '../service/authGuardService';
import { Avatar } from 'primereact/avatar';

const AppTopbar = (props: any) => {

    const [user, setUser] = useState<any>(null);
	const design = authGuardService.getDesign()


    useEffect(() => {
        setUser(authGuardService.getUser())
    }, []);

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <div className="topbar-menu-button p-link" onClick={props.onMenuButtonClick} style={{ background: 'transparent' }}>
                        <i className="pi pi-bars" style={{ color: design.textBackgroundColor ? design.textBackgroundColor : '#000000', fontSize: 18 }}></i>
                    </div>
                </div>

                <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                    onMenuButtonClick={props.onMenuButtonClick}
                />

            </div>
        </>
    )
}

export default AppTopbar;