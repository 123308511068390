import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import animationData from '../assets/loading_purple.json';

export const AddingAccount = (props: any) => {

	const history = useHistory();

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	function navigateToNextScreen() {
		history.push('/account-added');
	}

	setTimeout(navigateToNextScreen, 4000);

	return (
		<div style={{ background: '#eeeefa', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#eeeefa', height: '100vh', width: '100%' }}>
				<Lottie options={defaultOptions}
					height={'40vh'}
					width={'40vh'}
				/>
				<p style={{ fontSize: 22, color: 'black' }}>Agregando cuenta...</p>
				<p style={{ fontSize: 18, color: 'black' }}>Esto puede tardar varios segundos</p>
			</div>
		</div >
	)
}