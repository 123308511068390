import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const AddAccount = (props: any) => {

	const URL = props.state.url
	const history = useHistory();

	const [windowSize, setWindowSize] = useState(getWindowSize());

	useEffect(() => {
		function handleWindowResize() { setWindowSize(getWindowSize()); }
		window.addEventListener('resize', handleWindowResize);
		return () => { window.removeEventListener('resize', handleWindowResize) };
	}, []);


	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	/* @ts-ignore */
	var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
	/* @ts-ignore */
	var eventer = window[eventMethod];
	var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

	// Listen to message from child window
	/* @ts-ignore */
	eventer(messageEvent, function (e) {
		if (e.data === 'unnax:tokenized_credentials:cancel:done') {
			history.push({
				pathname: '/home',
			})
		} else if (e.data === 'unnax:tokenized_credentials:error_accept_button_pressed:done') {
			history.push({
				pathname: '/home',
			})
		} else if (e.data === 'unnax:aggregation:done') {
			history.push({
				pathname: '/home',
			})
		} else if (e.data === 'unnax:aggregation:fail') {
			history.push({
				pathname: '/home',
			})
		} else if (e.data === 'unnax:aggregation:cancel:done') {
			history.push({
				pathname: '/home',
			})
		} else if (e.data === 'unnax:tokenized_credentials:done') {
			history.push({
				pathname: '/home',
			})
		}
	});

	return (
		<iframe src={URL} width={windowSize.innerWidth} height={windowSize.innerHeight} />
	)
}