import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import animationData from '../assets/59625-card-tarjeta.json';
import authGuardService from '../service/authGuardService';
import TestService from '../service/testService';

const EmptyPage = () => {

    const design = authGuardService.getDesign()

    const history = useHistory();

    const userData = authGuardService.getUser();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [displayPosition2, setDisplayPosition2] = useState(false);
    const [displayPosition3, setDisplayPosition3] = useState(false);
    const [displayPosition4, setDisplayPosition4] = useState(false);

    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [position, setPosition] = useState('center');

    const [checked1, setChecked1] = useState(false);

    const [cards, setCards] = useState([])
    const [selectedCard, setSelectedCard] = useState<any>()

    useEffect(() => {
        getCards()
    }, []);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const dialogFuncMap: any = {
        'displayBasic': setDisplayBasic,
        'displayBasic2': setDisplayBasic2,
        'displayModal': setDisplayModal,
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
        'displayPosition2': setDisplayPosition2,
        'displayPosition3': setDisplayPosition3,
        'displayPosition4': setDisplayPosition4,
        'displayResponsive': setDisplayResponsive
    }

    const onClick = (name: string, position: React.SetStateAction<string>) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const renderFooter = (name: string) => {
        return (
            <div style={{ display: 'flex' }}>
                <Button label="Cerrar" className="p-button-text" style={{ fontWeight: 'bold', fontSize: 15, color: design.backgroundButtonColor || '#5c39e9', width: '50%', height: '6vh' }} onClick={() => onHide(name)} />
                <Button label="Entendido" className="p-button-text" style={{
                    fontWeight: 'bold', fontSize: 15, width: '50%', height: '6vh', color: design.textButtonColor || '#000',
                    background: design.backgroundButtonColor || '#5c39e9',
                    borderColor: 'transparent'
                }} onClick={() => onHide(name)} />
            </div>
        );
    }

    const renderFooterDelete = (name: string) => {
        return (
            <div style={{ display: 'flex' }}>
                <Button label="Cancelar" className="p-button-text" style={{
                    fontFamily: `${design?.typo || 'Roboto'}`,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: design.backgroundButtonColor || '#5c39e9',
                    width: '50%'
                }} onClick={() => onHide(name)} />
                <Button label="Borrar Cuenta" className="p-button-text" style={{
                    height: '52px',
                    borderRadius: '12px',
                    width: '50%',
                    fontFamily: `${design?.typo || 'Roboto'}`,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: design.textButtonColor || '#000',
                    background: design.backgroundButtonColor || '#5c39e9',
                    borderColor: 'transparent'

                }} onClick={() => deleteCard(name)} />
            </div>
        );
    }

    const renderFooterSend = (name: string) => {
        return (
            <div style={{ display: 'flex' }}>
                <Button label="Regresar" className="p-button-text" style={{
                    width: '50%',
                    fontFamily: `${design?.typo || 'Roboto'}`,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: design.backgroundButtonColor || '#5c39e9'
                }} onClick={() => onHide('displayPosition4')} />
                <Button label="Enviar a Ejecutivo" className="p-button-text" style={{
                    width: '50%',
                    height: '52px',
                    borderRadius: '12px',
                    fontFamily: `${design?.typo || 'Roboto'}`,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: design.textButtonColor || '#000',
                    background: design.backgroundButtonColor || '#5c39e9',
                    borderColor: 'transparent'
                }} onClick={() => sendInfo(name)} />
            </div>
        );
    }

    const onHide = (name: string) => {
        dialogFuncMap[`${name}`](false);
    }

    const deleteCard = async (name: string) => {
        await TestService.requestLoginDev()
            .then(async (responseLogin: any) => {
                await TestService.deleteCard(selectedCard.tokenDataId, responseLogin.data.access_token).then(async (request) => {
                    if (request.data.ok) {
                        notify()
                    } else {
                        notifyError()
                    }
                    getCards()
                }).catch(err => console.log(err))
            })
            .catch(err => notifyError())
        onHide(name)
    }

    const deleteModals = (name: string) => {
        onHide(name)
        onClick('displayPosition3', 'bottom')
    }

    const sendInfo = async (name: string) => {
        onHide(name)
        const solId = authGuardService.getSolId();
        await TestService.requestLoginDev()
            .then(async (responseLogin: any) => {
                await TestService.sendToExecutive(solId, responseLogin.data.access_token).then(async (request) => {
                    if (request.data.ok) {
                        history.push({
                            pathname: '/sending',
                            state: { cards: cards }
                        })
                    } else {
                        notifyErrorSend()
                    }
                }).catch(err => console.log(err))

                /* await TestService.sendToExecutiveTEST(req_code).then(async (request) => {
                    if (request.data.ok) {
                        history.push({
                            pathname: '/sending',
                            state: { cards: cards }
                        })
                    } else {
                        notifyErrorSend()
                    }
                }).catch(err => console.log(err)) */
            })
            .catch(err => console.log(err))
    }

    const notify = () => toast.custom(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '16px',
            width: '100%',
            height: '52px',
            background: '#31D583',
            borderRadius: '0px 0px 8px 8px',
        }}>
            <span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <i
                    className="pi pi-check-circle"
                    style={{
                        color: '#FFF',
                        marginRight: 5
                    }}
                />
                <p
                    style={{
                        fontFamily: `${design?.typo || 'Roboto'}`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: ' #FFFFFF'
                    }}>¡La cuenta ha sido borrada!</p>
            </span>
        </div >
    );

    const notifyError = () => toast.custom(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '12px 16px',
            gap: '8px',
            width: '100%',
            height: '60px',
            background: '#F8494F',
            boxShadow: '0px 4px 16px - 6px rgba(36, 22, 77, 0.05)',
            backdropFilter: 'blur(2px)',
            borderRadius: '0px 0px 8px 8px',

        }}>
            <span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <p
                    style={{
                        fontFamily: `${design?.typo || 'Roboto'}`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#FFFFFF',
                    }}>¡Oops! No se ha podido borrar la cuenta. Inténtalo nuevamente.</p>
            </span>
        </div >
    );

    const notifyErrorSend = () => toast.custom(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '12px 16px',
            gap: '8px',
            width: '100%',
            height: '60px',
            background: '#F8494F',
            boxShadow: '0px 4px 16px - 6px rgba(36, 22, 77, 0.05)',
            backdropFilter: 'blur(2px)',
            borderRadius: '0px 0px 8px 8px',

        }}>
            <span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <p
                    style={{
                        fontFamily: `${design?.typo || 'Roboto'}`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#FFFFFF',
                    }}>¡Oops! Hubo un error al enviar los datos con el ejecutivo. Inténtalo nuevamente.</p>
            </span>
        </div >
    );

    const initializeUnnax = async () => {
        const solId = authGuardService.getSolId();

        await TestService.requestLoginDev()
            .then(async (responseLogin: any) => {
                if (responseLogin.statusText === 'OK') {
                    await TestService.loginUnnax(responseLogin.data.access_token, solId).then((responseLoginUnnax: any) => {
                        history.push({
                            pathname: '/add-account',
                            state: { url: responseLoginUnnax.data.widgetUrl }
                        })
                        authGuardService.sendUser(responseLoginUnnax.data)
                    })
                }
                else notifyError()
            })
            .catch(err => console.log(err))
    }

    const getCards = async () => {
        const solId = authGuardService.getSolId();
        await TestService.requestLoginDev()
            .then(async (responseLogin: any) => {
                await TestService.getCards(solId, responseLogin.data.access_token).then(async (request) => {
                    setCards(request.data)
                }).catch(err => console.log(err))

                /* await TestService.getCardsTEST(req_code).then(async (requestTEST) => {
                    setCards(requestTEST.data)
                }).catch(err => console.log(err)) */
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
                <Toaster
                    position="top-center"
                    containerClassName=""
                    containerStyle={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                    }}
                />

                <div>
                    <div>
                        <Dialog
                            visible={displayPosition}
                            position={'bottom'}
                            modal
                            style={{ maxWidth: '100vw', backgroundColor: '#FFF' }}
                            contentStyle={{ background: '#FFF' }}
                            footer={renderFooter('displayPosition')}
                            onHide={() => onHide('displayPosition')}
                            draggable={false}
                            resizable={false}
                            closable={false}
                        >
                            <p style={{ fontSize: 22, fontWeight: 'bold', color: '#4f4f4f' }}>¿Por qué te pedimos las claves de acceso?</p>
                            <p className="m-0">• Aunque nos des tus claves, nosotros no podremos hacer movimientos de ningún tipo en tus cuentas, solo accedemos para leer la información.</p>
                            <p className="m-0">• Si nunca has usado su portal web o no recuerdas tus claves de acceso, puedes ponerte en contacto con la entidad para obtenerlas.</p>
                        </Dialog>

                        <Dialog
                            visible={displayPosition3}
                            position={'bottom'}
                            modal
                            style={{ maxWidth: '100%', background: '#FFF' }}
                            contentStyle={{ background: '#FFF' }}
                            showHeader={true}
                            footer={renderFooterDelete('displayPosition3')}
                            onHide={() => onHide('displayPosition3')}
                            draggable={false}
                            resizable={false}
                            closable={false}
                        >
                            <p style={{
                                fontFamily: `${design?.typo || 'Roboto'}`,
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '28px',
                                color: '#4F4F4F'
                            }}>{`¿Estás seguro que quieres borrar la cuenta ${selectedCard && selectedCard.bank ? `de ${selectedCard.bank}` : ''}?`}</p>
                            <p style={{
                                fontFamily: `${design?.typo || 'Roboto'}`,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '17px',
                                lineHeight: '24px',
                                color: '#45474D',
                            }}>Tener un panorama de todas tus cuentas ayudará a que puedas obtener mejores oportunidades de crédito.</p>
                        </Dialog>

                        <Dialog
                            visible={displayPosition4}
                            position={'bottom'}
                            modal
                            style={{ maxWidth: '100vw', background: '#FFF' }}
                            contentStyle={{ background: '#FFF' }}
                            footer={renderFooterSend('displayPosition4')}
                            onHide={() => onHide('displayPosition4')}
                            draggable={false}
                            resizable={false}
                            closable={false}
                        >
                            <p style={{
                                fontFamily: `${design?.typo || 'Roboto'}`,
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '28px',
                                color: '#4F4F4F'
                            }}>Asegúrate de haber agregado todas tus cuentas.</p>
                            <p style={{
                                fontFamily: `${design?.typo || 'Roboto'}`,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '17px',
                                lineHeight: '24px',
                                color: '#45474D'
                            }}>Enviar todas tus cuentas te ayudará a obtener mejores oportunidades de crédito.</p>
                        </Dialog>

                        <Dialog
                            visible={displayPosition2}
                            position={'bottom'}
                            modal={true}
                            style={{ maxWidth: '100%', background: 'transparent' }}
                            contentStyle={{ background: 'transparent' }}
                            showHeader={false}
                            onHide={() => onHide('displayPosition2')}
                            draggable={false}
                            resizable={false}
                            closable={false}
                        >
                            <div
                                style={{ display: 'flex', alignItems: 'center', borderRadius: 6, background: '#ffffff', flexDirection: 'column', marginTop: '2rem', minWidth: '24rem' }}
                                onClick={() => deleteModals('displayPosition2')}
                            >
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    background: '#FFFFFF',
                                    border: '1px solid #EDF0F2',
                                    borderRadius: '12px',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '56px'
                                }}>
                                    <div style={{ width: '80%' }}>
                                        <p style={{
                                            fontFamily: `${design?.typo || 'Roboto'}`,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '19px',
                                            color: '#F04437'
                                        }}>Borrar Cuenta</p>
                                    </div>
                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <i style={{ color: '#F04437', fontSize: 20 }} className="pi pi-trash"></i>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', borderRadius: 6, background: '#ffffff', flexDirection: 'column', marginTop: '2rem', minWidth: '24rem' }} onClick={() => onHide('displayPosition2')}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    background: '#FFFFFF',
                                    border: '1px solid #EDF0F2',
                                    borderRadius: '12px',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '56px'
                                }}>
                                    <p style={{
                                        fontFamily: `${design?.typo || 'Roboto'}`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '19px',
                                        color: '#212426'
                                    }}>Cerrar</p>
                                </div>
                            </div>
                        </Dialog>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                            <p style={{
                                fontFamily: `${design?.typo || 'Roboto'}`,
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '24px',
                                lineHeight: '32px',
                                color: '#45474D'
                            }}>Mis cuentas</p>
                            {(cards.length > 0) &&
                                <Button icon="pi pi-plus" style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '12px',
                                    color: design.textButtonColor || '#000',
                                    background: design.backgroundButtonColor || '#5c39e9',
                                    borderColor: 'transparent'
                                }} onClick={initializeUnnax} />

                            }
                        </div>

                        {cards.length > 0 &&
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <p style={{
                                    fontFamily: `${design?.typo || 'Roboto'}`,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '#45474D',
                                }}>Estas son las cuentas que has agregado</p>
                            </div>
                        }

                        {
                            !(cards.length > 0) &&
                            < div style={{
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '16px 8px',
                                height: '92px',
                                background: '#EDF0F2',
                                border: '1px solid #EDF0F2',
                                borderRadius: '10px',
                            }} onClick={() => onClick('displayPosition', 'bottom')}>
                                <div style={{ width: '35%', justifyContent: 'center', display: 'flex', marginTop: 15, marginBottom: 15 }}>
                                    <i className="pi pi-exclamation-circle" style={{ 'fontSize': '1.5em', color: design.textBackgroundColor ? design.textBackgroundColor : '#000000' }}></i>
                                </div>
                                <div style={{ marginTop: 15, marginBottom: 15 }}>
                                    <p style={{
                                        fontFamily: `${design?.typo || 'Roboto'}`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#45474D'
                                    }}>
                                        Al agregar tus cuentas de otros bancos tendrás más y mejores oportunidades de crédito.
                                    </p>
                                </div>
                            </div>
                        }

                        {cards.length > 0 &&
                            <>
                                {
                                    cards.map((card: any) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderRadius: 6,
                                                    background: '#ffffff',
                                                    flexDirection: 'column',
                                                    marginTop: '2rem',
                                                    minWidth: '24rem'
                                                }}
                                                key={card.token_key}
                                                onClick={() => {
                                                    setSelectedCard(card)
                                                    onClick('displayPosition2', 'bottom')
                                                }}
                                            >
                                                <div style={{ width: '100%', display: 'flex', marginTop: '1rem', marginRight: '1rem', marginLeft: '4rem' }}>
                                                    <div style={{ width: '80%' }}>
                                                        <p style={{
                                                            fontFamily: `${design?.typo || 'Roboto'}`,
                                                            fontStyle: 'normal',
                                                            fontWeight: 700,
                                                            fontSize: '12px',
                                                            lineHeight: '16px',
                                                            color: '#8A9196'
                                                        }}>{card.bank}</p>
                                                    </div>
                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <i style={{ color: '#45474D', fontSize: 15 }} className="pi pi-ellipsis-h"></i>
                                                    </div>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', marginTop: '0.5rem', marginBottom: '1.5rem', marginRight: '1rem', marginLeft: '4rem' }}>
                                                    <div style={{ width: '80%' }}>
                                                        <p style={{
                                                            fontFamily: `${design?.typo || 'Roboto'}`,
                                                            fontStyle: 'normal',
                                                            fontWeight: 600,
                                                            fontSize: '20px',
                                                            lineHeight: '28px',
                                                            color: '#45474D'
                                                        }}>••••••••</p>
                                                    </div>
                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <i style={{ color: '#31D583', fontSize: 20 }} className="pi pi-chevron-circle-down"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </>

                        }


                        {!(cards.length > 0) &&
                            <>
                                <Lottie options={defaultOptions}
                                    height={'30vh'}
                                    width={'30vh'}
                                />
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{
                                        fontFamily: `${design?.typo || 'Roboto'}`,
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '20px',
                                        lineHeight: '28px',
                                        textAlign: 'center',
                                        color: '#212426'
                                    }}>¡ Bienvenido !</p>
                                    <Button label="Agregar cuenta para empezar" icon="pi pi-plus" style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '40px',
                                        borderRadius: '12px',
                                        width: '250px',
                                        fontFamily: `${design?.typo || 'Roboto'}`,
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: ' 14px',
                                        lineHeight: '20px',
                                        color: design.textButtonColor || '#000',
                                        background: design.backgroundButtonColor || '#5c39e9',
                                        borderColor: 'transparent'
                                    }} onClick={initializeUnnax} />
                                </div>
                            </>

                        }

                        {(cards.length > 0) &&
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end', marginTop: '2rem', justifySelf: 'end' }}>
                                <Button label="Agregar cuenta" icon="pi pi-plus" style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0px 24px 24px -8px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '12px',
                                    width: '161px',
                                    height: '40px',
                                    padding: '8px 16px',
                                    gap: '8px',

                                    fontFamily: `${design?.typo || 'Roboto'}`,
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '20px',

                                    color: design.textButtonColor || '#000',
                                    background: design.backgroundButtonColor || '#5c39e9',
                                    borderColor: 'transparent'
                                }} onClick={initializeUnnax} />
                            </div>
                        }

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20, minWidth: '24rem' }}>
                            <Button
                                disabled={!(cards.length > 0)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '16px 12px',
                                    gap: '8px',
                                    width: '320px',
                                    height: '56px',
                                    background: design.backgroundButtonColor || '#5c39e9',
                                    border: `1px solid ${design.backgroundButtonColor || '#5c39e9'}`,
                                    boxShadow: '0px 24px 24px -8px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '12px'
                                }} onClick={() => onClick('displayPosition4', 'bottom')} >

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                    <div>
                                        <i className="pi pi-send" style={{
                                            color: design.textButtonColor || '#5c39e9',
                                            marginRight: 5,
                                            fontSize: 15
                                        }}></i>
                                    </div>
                                    <div>
                                        <p style={{
                                            fontFamily: `${design?.typo || 'Roboto'}`,
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: design.textButtonColor || '#5c39e9'
                                        }}>
                                            Enviar a Ejecutivo
                                        </p>
                                    </div>
                                </div>
                            </Button>
                        </div>

                    </div>
                </div>
            </div >
        </>
    );

}

export default React.memo(EmptyPage);