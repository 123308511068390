import { useHistory } from 'react-router-dom';

export const NoticeOfPrivacy = (props: any) => {

	const history = useHistory();

	return (
		<div style={{ background: '#eeeefa', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem', width: '100%' }} onClick={() => history.goBack()}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<i className="pi pi-arrow-left" style={{ color: 'black', marginRight: '6vh', marginLeft: '-6vh' }} />
					<p style={{ fontSize: 15, color: 'black' }}>Aviso de privacidad</p>
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#eeeefa', height: '100vh', width: '100%' }}>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '3rem' }}>
					<p style={{
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '20px',
						lineHeight: '24px',
						textAlign: 'center',
						color: '#2C3747'
					}}>Aviso de privacidad</p>
					<p style={{
						margin: '3rem',
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '16px',
						lineHeight: '20px',
						color: '#2C3747',
					}}>

						Autorizo expresamente a Accionamos Tus Datos, S.A. DE C.V., comercialmente denominada “ELIIOT” para que lleve a cabo investigaciones sobre mi comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente.<br />

						<br />Conozco la naturaleza y alcance de la información que se solicitará, del uso que se le dará y que se podrá realizar consultas periodicas de mi historial crediticio.<br />

						<br />Consiento que esta autorización tenga una vigencia de 3 años contando a partir de hoy y en su caso mientras mantengamos relación jurídica.<br />

						<br />Acepto que este documento quede bajo propiedad de eliiot para efectos de control y cumplimiento del artículo 28 de la LRSIC.<br />
					</p>
				</div>
			</div>
		</div >
	)
}