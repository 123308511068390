import Lottie from 'react-lottie';
import loadingAnimation from '../assets/check_white.json';
import authGuardService from '../service/authGuardService';

export const LastScreen = (props: any) => {

    const design = authGuardService.getDesign()

	const checkOptions = {
		loop: false,
		autoplay: true,
		animationData: loadingAnimation
	};

	return (
		<div style={{ background: design.backgroundColor || '#5c39e9', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

			<div style={{ display: 'flex', justifyContent: 'center', background: design.backgroundColor || '#5c39e9', width: '100%' }}>
				<div style={{ display: 'flex', marginTop: '10rem', marginRight: '2rem', marginLeft: '2rem', flexDirection: 'column', alignItems: 'center' }}>
					<div>
						<Lottie options={checkOptions}
							height={'15vh'}
							width={'15vh'}
						/>
					</div>
					<div style={{ marginLeft: '0.5rem', marginBottom: '4rem', marginTop: '4rem', display: 'center', }}>
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '24px',
							lineHeight: '32px',
							color: '#FFFFFF'
						}}>¡Gracias!</p>
					</div>
				</div>
			</div>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#FFF', width: '100%' }}>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '8vh', width: '100%', maxWidth: '25rem' }}>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '20px',
						color: '#45474D',
						textAlign: 'center'
					}}>Tu Ejecutivo analizará tu información y buscará las mejores oportunidades de crédito para ti.</p>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '3rem' }}>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '17px',
						lineHeight: '24px',
						textAlign: 'center',
						color: '#212426'
					}}>Ten paciencia y ¡Mucho éxito! </p>
				</div>
			</div>

		</div >
	)
}