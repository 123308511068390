import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const AddBank = (props: any) => {

	const history = useHistory();
	const [checked, setChecked] = useState(false);

	const [displayBasic, setDisplayBasic] = useState(false);
	const [displayBasic2, setDisplayBasic2] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [displayMaximizable, setDisplayMaximizable] = useState(false);
	const [displayPosition, setDisplayPosition] = useState(false);
	const [displayResponsive, setDisplayResponsive] = useState(false);
	const [position, setPosition] = useState('center');

	const dialogFuncMap: any = {
		'displayBasic': setDisplayBasic,
		'displayBasic2': setDisplayBasic2,
		'displayModal': setDisplayModal,
		'displayMaximizable': setDisplayMaximizable,
		'displayPosition': setDisplayPosition,
		'displayResponsive': setDisplayResponsive
	}

	const onClick = (name: string, position: React.SetStateAction<string>) => {
		dialogFuncMap[`${name}`](true);

		if (position) {
			setPosition(position);
		}
	}

	const renderFooter = (name: string) => {
		return (
			<div style={{ display: 'flex' }}>
				<Button label="Cerrar" className="p-button-text" style={{ fontWeight: 'bold', fontSize: 15, color: '#5c39eb', width: '50%', height: '6vh' }} onClick={() => onHide(name)} />
				<Button label="Entendido" className="p-button-text" style={{ fontWeight: 'bold', fontSize: 15, color: 'white', width: '50%', height: '6vh', background: '#5c39eb' }} onClick={() => onHide(name)} />
			</div>
		);
	}

	const onHide = (name: string) => {
		dialogFuncMap[`${name}`](false);
	}

	return (
		<div style={{ background: '#eeeefa', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

			<Dialog visible={displayPosition} position={'bottom'} modal style={{ maxWidth: '100vw' }} footer={renderFooter('displayPosition')} onHide={() => onHide('displayPosition')} draggable={false} resizable={false} closable={false}>
				<p style={{ fontSize: 22, fontWeight: 'bold', color: '#4f4f4f' }}>¿Por qué te pedimos las claves de acceso?</p>
				<p className="m-0">• Aunque nos des tus claves, nosotros no podremos hacer movimientos de ningún tipo en tus cuentas, solo accedemos para leer la información.</p>
				<p className="m-0">• Si nunca has usado su portal web o no recuerdas tus claves de acceso, puedes ponerte en contacto con la entidad para obtenerlas.</p>
			</Dialog>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem', width: '100%' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<i className="pi pi-arrow-left" style={{ color: 'black', marginRight: '6vh', marginLeft: '-6vh' }} onClick={() => history.push('/add-account')} />
					<p style={{ fontSize: 15, color: 'black' }}>{props?.state?.bank?.bankName} - Personas</p>
				</div>
			</div>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#eeeefa', height: '100vh', width: '100%' }}>

				<div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'center', marginTop: '3rem', margin: '2.5rem' }}>
					<p style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>Ingresa tus datos de acceso a {props?.state?.bank?.bankName} - Personas</p>
					<p style={{ fontSize: 14, color: 'black' }}>Son las claves que usas para entrar al portal web. Para agregar esta cuenta necesitas tu TOKEN, puedes desactivarlo desde el portal.</p>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', marginTop: '3vh', width: '100%', maxWidth: '23rem' }}>
					<p style={{ fontSize: 13, fontWeight: 'bold', color: 'black' }}>Número de tarjeta / cliente</p>
					<InputText placeholder="16 dígitos" style={{ width: '100%', height: '3rem' }} />
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', marginTop: '3vh', width: '100%', maxWidth: '23rem' }}>
					<p style={{ fontSize: 13, fontWeight: 'bold', color: 'black' }}>Número de tarjeta / cliente</p>
					<Password placeholder={"Contraseña"} toggleMask feedback={false} style={{ width: '100%', height: '3rem' }} inputStyle={{ width: "100%" }} />
				</div>

				<div className="field-checkbox" style={{ marginLeft: '2.5rem', marginTop: '2rem', alignSelf: 'start' }}>
					<Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
					<label htmlFor="binary">He leído y acepto los Términos y condiciones</label>
				</div>

				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 6, width: '90%', marginTop: '6rem', border: '1px solid lightgrey' }} onClick={() => onClick('displayPosition', 'bottom')}>
					<div style={{ width: '15%', justifyContent: 'center', display: 'flex', marginTop: 15, marginBottom: 15 }}>
						<i className="pi pi-exclamation-circle" style={{ 'fontSize': '1.5em', color: 'blue' }}></i>
					</div>
					<div style={{ width: '85%', marginTop: 15, marginBottom: 15 }}>
						<p style={{ fontSize: 14, color: 'black' }}>¿Por qué te pédimos las claves de acceso?</p>
					</div>
				</div>

				<div style={{ marginTop: '3rem' }}>
					<Button label="Agregar" className="p-button-text p-button-rounded" style={{ width: '10rem', height: '3rem', background: '#5c39e9', color: 'white' }} onClick={() => history.push('/adding-account')}/>
				</div>
			</div>
		</div >
	)
}