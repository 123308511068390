import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import checkAnimation from '../assets/check_green.json';
import loadingAnimation from '../assets/loading_purple.json';


export const AccountAdded = (props: any) => {

	const history = useHistory();

	const checkOptions = {
		loop: false,
		autoplay: true,
		animationData: checkAnimation,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	const loadingOptions = {
		loop: false,
		autoplay: true,
		animationData: loadingAnimation,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	 function navigateToNextScreen() {
		history.push('/home');
	}

	setTimeout(navigateToNextScreen, 5000);

	return (
		<div style={{ background: '#eeeefa', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#eeeefa', height: '100vh', width: '90%', marginTop: '10vh' }}>
				<Lottie options={checkOptions}
					height={'20vh'}
					width={'20vh'}
				/>
				<p style={{ fontSize: 25, color: 'black', fontWeight: 'bold', textAlign: 'center', marginTop: 30 }}>Se está cargando tu información</p>
				<p style={{ fontSize: 20, color: 'black', textAlign: 'center', marginTop: 30 }}>La conexión a tus finanzas se ha realizado con éxito</p>

				<Lottie options={loadingOptions}
					height={'40vh'}
					width={'40vh'}
					style={{ marginTop: '-8rem' }}
				/>

				<p style={{ fontSize: 20, color: 'black', textAlign: 'center', marginTop: '-5rem' }}> Redirigiendo... </p>

			</div>
		</div >
	)
}