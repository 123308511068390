import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const NotFound = (props: any) => {

	const history = useHistory();

	const handleClick = () => history.push('/');


	return (
		<div className="exception-body notfound">
			<div className="exception-panel">
				<h1>404</h1>
				<h3>Not Found</h3>
				<p>La pagina que estas buscando no existe</p>

				<Button type="button" label="Regresar" onClick={handleClick}></Button>

			</div>
			<div className="exception-footer">
				<img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-logo" alt="expection-logo" />
				<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-appname" alt="expection-appname"/>
			</div>
		</div>
	)
}