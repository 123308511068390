import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import checkAnimation from '../assets/check_grey.json';

export const AllSet = (props: any) => {

	const history = useHistory();

	const checkOptions = {
		loop: false,
		autoplay: true,
		animationData: checkAnimation,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	function navigateToNextScreen() {
		history.push('/home');
	}

	setTimeout(navigateToNextScreen, 5000);

	return (
		<div style={{ background: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#FFF', height: '100vh', width: '90%', marginTop: '10vh' }}>
				<Lottie options={checkOptions}
					height={'20vh'}
					width={'20vh'}
				/>
				<p style={{
					fontFamily: 'Roboto',
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize: '24px',
					lineHeight: '32px',
					textAlign: 'center',
					color: '#45474D',
					marginTop: 70
				}}>¡Genial, todo listo!</p>
				<p style={{
					fontFamily: 'Roboto',
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '20px',
					textAlign: 'center',
					color: '#666E70',
					marginTop: 40
				}}>Ahora agrega las cuentas que quieres enviar a tu ejecutivo.</p>

			</div>
		</div >
	)
}