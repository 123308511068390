import Carousel from 'nuka-carousel';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import image1 from '../assets/slides/slide1.png';
import image2 from '../assets/slides/slide2.png';
import image3 from '../assets/slides/slide3.png';
import authGuardService from '../service/authGuardService';
import { ProgressSpinner } from 'primereact/progressspinner';
import TestService from '../service/testService';
import { Skeleton } from 'primereact/skeleton';

export const Slides = (props: any) => {

	const history = useHistory();

	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 768px)").matches
	)

	const [design, setDesign] = useState<any>()

	const [indexSlide, setIndexSlide] = useState(0)

	useEffect(() => {
		window
			.matchMedia("(min-width: 768px)")
			.addEventListener('change', e => setMatches(e.matches));
	}, []);

	const getDesignAPI = async () => {
		const urlArray = window.location.href.split('/')
		await TestService.requestLoginDev()
			.then(async (responseLogin: any) => {
				if (Number.isInteger(parseInt(urlArray[urlArray.length - 1], 10))) {
					authGuardService.sendCliID(urlArray[urlArray.length - 1])
					await TestService.getDesign(urlArray[urlArray.length - 1], responseLogin.data.access_token)
						.then(async (response) => {
							if (response.data._embedded.disenios.length > 0) {
								const extraDesign = response.data._embedded.disenios[0].tipogr == 'tipogr' ? { align: { name: 'center' }, typo: { name: 'roboto' } } : JSON.parse(response.data._embedded.disenios[0].tipogr)
								authGuardService.saveDesign({
									backgroundColor: response.data._embedded.disenios[0].fondo,
									textBackgroundColor: response.data._embedded.disenios[0].txt,
									backgroundButtonColor: response.data._embedded.disenios[0].btn,
									textButtonColor: response.data._embedded.disenios[0].txtbtn,
									logo: response.data._embedded.disenios[0].logo,
									align: extraDesign.align.name,
									typo: extraDesign.typo.name
								})
								setDesign({
									backgroundColor: response.data._embedded.disenios[0].fondo,
									textBackgroundColor: response.data._embedded.disenios[0].txt,
									backgroundButtonColor: response.data._embedded.disenios[0].btn,
									textButtonColor: response.data._embedded.disenios[0].txtbtn,
									logo: response.data._embedded.disenios[0].logo,
									align: extraDesign.align.name,
									typo: extraDesign.typo.name
								})
							} else {
								authGuardService.saveDesign({
									backgroundColor: '#01963A',
									textBackgroundColor: '#FFFFFF',
									backgroundButtonColor: '#F2C91A',
									textButtonColor: '#000000',
									align: 'Centrado',
									typo: 'Roboto'
								})
								setDesign({
									backgroundColor: '#01963A',
									textBackgroundColor: '#FFFFFF',
									backgroundButtonColor: '#F2C91A',
									textButtonColor: '#000000',
									align: 'Centrado',
									typo: 'Roboto'
								})
							}
						})
						.catch(err => console.log(err))
				} else {
					authGuardService.saveDesign({
						backgroundColor: '#01963A',
						textBackgroundColor: '#FFFFFF',
						backgroundButtonColor: '#F2C91A',
						textButtonColor: '#000000',
						align: 'Centrado',
						typo: 'Roboto'
					})
					setDesign({
						backgroundColor: '#01963A',
						textBackgroundColor: '#FFFFFF',
						backgroundButtonColor: '#F2C91A',
						textButtonColor: '#000000',
						align: 'Centrado',
						typo: 'Roboto'
					})
				}
			})
			.catch(err => console.log(err))

	}

	useEffect(() => {
		getDesignAPI()
	}, [])

	return (
		<>
			{design ?
				<div style={{ background: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

					{matches && (
						<Carousel withoutControls={true}>
							<Image src={image1} alt="Image Text" width={'30%'} />
							<Image src={image2} alt="Image Text" width={'30%'} />
							<Image src={image3} alt="Image Text" width={'30%'} />
						</Carousel>
					)}
					{!matches && (
						<Carousel
							withoutControls={true}
							slideIndex={indexSlide}
							dragging={true}
							afterSlide={(e) => setIndexSlide(e)} zoomScale={.85}
						>
							<Image src={image1} alt="Image Text" width={'100%'} />
							<Image src={image2} alt="Image Text" width={'100%'} />
							<Image src={image3} alt="Image Text" width={'100%'} />
						</Carousel>
					)}

					<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: '2rem' }}>
						<p style={{
							fontSize: 23,
							fontWeight: 'bold',
							color: indexSlide == 0 ? design?.backgroundButtonColor || '#f2c91a' : '#cccccc',
							textAlign: 'center'
						}}> • </p>&nbsp;&nbsp;
						<p style={{
							fontSize: 23,
							fontWeight: 'bold',
							color: indexSlide == 1 ? design?.backgroundButtonColor || '#f2c91a' : '#cccccc',
							textAlign: 'center'
						}}> • </p>&nbsp;&nbsp;
						<p style={{
							fontSize: 23,
							fontWeight: 'bold',
							color: indexSlide == 2 ? design?.backgroundButtonColor || '#f2c91a' : '#cccccc',
							textAlign: 'center'
						}}> • </p>&nbsp;&nbsp;
					</div>

					<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginLeft: '2rem', marginRight: '2rem' }}>

						{
							indexSlide == 0 &&
							<p style={{
								fontSize: 23,
								fontWeight: 'bold',
								color: 'black',
								textAlign: 'center',
								fontFamily: `${design?.typo || 'Roboto'}`
							}}>Obten mejores oportunidades de crédito</p>
						}

						{
							indexSlide == 1 &&
							<p style={{
								fontSize: 23,
								fontWeight: 'bold',
								color: 'black',
								textAlign: 'center',
								fontFamily: `${design?.typo || 'Roboto'}`
							}}>Para viajes, proyectos o emergencias</p>
						}

						{
							indexSlide == 2 &&
							<p style={{
								fontSize: 23,
								fontWeight: 'bold',
								color: 'black',
								textAlign: 'center',
								fontFamily: `${design?.typo || 'Roboto'}`
							}}>De forma rápida y fácil</p>
						}
					</div>

					<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '5rem', width: '90%' }}>
						<div style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							padding: '16px',
							width: '100%',
							maxWidth: '25rem',
							height: '56px',
							background: design?.backgroundButtonColor || '#F2C91A',
							borderRadius: '12px',
							marginTop: 0,
							marginBottom: 16
						}}
							onClick={() => history.push('/login')}
						>
							<p style={{
								display: 'flex',
								fontStyle: 'normal',
								fontWeight: 600,
								fontSize: '14px',
								color: design?.textButtonColor || '#FFF',
								fontFamily: `${design?.typo || 'Roboto'}`
							}}>Comenzar</p>
							<i
								className="pi pi-arrow-right"
								style={{
									marginTop: -1,
									marginLeft: 13,
									display: 'flex',
									alignSelf: 'center',
									fontSize: '15px',
									color: design?.textButtonColor || '#FFF',
									fontWeight: '600'
								}}></i>
						</div>
					</div>

				</div >

				:
				<div className="card">
					<div className="grid formgrid">
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
							<Skeleton width="100vh" height="50rem"></Skeleton>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 40 }}>
							<Skeleton width="20rem" height="5rem" borderRadius="16px"></Skeleton>
						</div>
					</div>
				</div>
			}
		</>

	)
}