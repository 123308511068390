import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import authGuardService from '../service/authGuardService';
import TestService from '../service/testService';

export const VerifyNumber = (props: any) => {

	const design = authGuardService.getDesign()
	const phone = authGuardService.getToken()
	const email = authGuardService.getEmail()
	const cliId = authGuardService.getCliId()

	const history = useHistory();

	const [code, setCode] = useState<string>('')
	const [codeValid, setCodeValid] = useState(true)
	const [codeToken, setCodeToken] = useState(props.state.smsToken)

	const validateCode = (value: string) => {
		setCodeValid(value.length == 4)
		setCode(value)
	}

	const notify = () => toast.custom(
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: '16px',
			width: '100%',
			height: '52px',
			background: '#31D583',
			borderRadius: '0px 0px 8px 8px',
		}}>
			<span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
				<i
					className="pi pi-check-circle"
					style={{
						color: '#FFF',
						marginRight: 5
					}}
				/>
				<p
					style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '20px',
						color: ' #FFFFFF'
					}}>¡Código reenviado!</p>
			</span>
		</div >
	);

	const notifyError = () => toast.custom(
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: '12px 16px',
			gap: '8px',
			width: '100%',
			height: '60px',
			background: '#F8494F',
			boxShadow: '0px 4px 16px - 6px rgba(36, 22, 77, 0.05)',
			backdropFilter: 'blur(2px)',
			borderRadius: '0px 0px 8px 8px',

		}}>
			<span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
				<p
					style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '18px',
						color: '#FFFFFF',
					}}>¡Uy! Código incorrecto, prueba de nuevo o pide uno nuevo.</p>
			</span>
		</div >
	);

	const verifyCode = async () => {
		const solidId = authGuardService.getSolId()
		await TestService.requestLoginDev()
			.then(async (responseLogin: any) => {
				await TestService.verifyCode(codeToken, code, responseLogin.data.access_token)
					.then(async (responseValidate: any) => {
						if (responseValidate.data.ok) {
							/* await TestService.loginUnnax(responseLogin.data.access_token, solidId).then((responseLoginUnnax: any) => {
								authGuardService.sendUser(responseLoginUnnax.data) */
								history.push('/all-set')
							/* }) */

							const email = authGuardService.getEmail()
							const phone = authGuardService.getToken()
							const cliId = authGuardService.getCliId()

							/* await TestService.loginUnnaxTEST(phone, email, cliId).then((responseLoginUnnaxTEST: any) => {
								console.log('responseLoginUnnaxTEST: ', responseLoginUnnaxTEST)
								authGuardService.sendUser(responseLoginUnnaxTEST.data)
								history.push('/all-set')
							}) */
						}
						else notifyError()
					})
					.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
	}

	const requestNewCode = async () => {
		const phone = authGuardService.getToken()
		await TestService.requestLoginDev()
			.then(async (responseLogin: any) => {
				await TestService.requestSMS(phone, cliId, email, responseLogin.data.access_token)
					.then(async (response) => {
						if (response.data.ok) {
							notify()
							setCodeToken(response.data.mensaje)
							authGuardService.setSolId(response.data.solId)
						}
					})
					.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
	}


	return (
		<div style={{ background: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Toaster
				position="top-center"
				containerClassName=""
				containerStyle={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
				}}
			/>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem', width: '100%' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<i className="pi pi-arrow-left" style={{ color: 'black', marginRight: '6vh', marginLeft: '-6vh' }} onClick={() => history.push('/login')} />
					<p style={{ fontSize: 15, color: 'black' }}>Verificar número</p>
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#fff', height: '100%', width: '100%' }}>
				<div style={{
					display: 'flex',
					justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '3rem'
				}}>
					<p style={{
						/* fontSize: 23,
						fontWeight: 'bold',
						color: 'black',
						textAlign: 'center' */
						marginRight: 16,
						marginLeft: 16,
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '24px',
						lineHeight: '32px',
						textAlign: 'center',
						color: '#212426'
					}}>Ingrese el código que se te envio por SMS</p>
					<div style={{ display: 'flex', marginTop: 16 }}>
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: '400',
							fontSize: '14px',
							lineHeight: '20px',
							textAlign: 'center',
							color: '#666E70',
						}}>Se envió el código al número </p>
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '20px',
							textAlign: 'center',
							color: '#666E70',
							marginLeft: 5
						}}>{`+52${phone}`}</p>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', marginTop: '8vh', width: '100%', maxWidth: '23rem' }}>
					<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<InputText
							id="integer"
							keyfilter="int"
							maxLength={4}
							placeholder='_ _ _ _'
							onChange={(e) => validateCode(e.target.value)}
							className={!codeValid ? "p-invalid block" : ''}
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								padding: '12px 16px',
								gap: '8px',
								width: '180px',
								height: '48px',
								background: '#F7F7F7',
								borderRadius: '8px',
								flex: 'none',
								order: 1,
								alignSelf: 'stretch',
								flexGrow: 0,
								fontFamily: `${design?.typo || 'Roboto'}`,
								fontStyle: 'normal',
								fontWeight: 400,
								fontSize: '17px',
								lineHeight: '24px',
								textAlign: 'center',
								color: '#212426',
								borderColor: codeValid ? 'transparent' : ''
							}}
						/>
					</span>
					{
						!codeValid &&
						<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<small id="username2-help" className="p-error block" style={{ marginTop: 5 }}>
								<i className="pi pi-exclamation-circle" style={{ marginRight: 5 }} />
								Ingresa los 4 digitos
							</small>
						</span>
					}
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '10vh', width: '90%' }}>
					<Button
						label='Activar cuenta'
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '16px',
							gap: '8px',
							width: '100%',
							height: '52px',
							borderRadius: '12px',
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 600,
							fontSize: '14px',
							lineHeight: '20px',
							color: design ? design.textButtonColor : '#000',
							background: design ? design.backgroundButtonColor : '#5c39e9',
							borderColor: 'transparent'
						}}
						onClick={() => verifyCode()}
						disabled={code.length != 4}
					/>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: '8vh', width: '100%', maxWidth: '25rem' }}>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '20px',
						color: '#45474D',
					}}>
						¿No recibiste el código?
					</p>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '20px',
						color: design ? design.backgroundButtonColor : '#5c39e9',
						marginLeft: 5
					}} onClick={() => requestNewCode()}>
						Reenviar mi código
					</p>
				</div>


			</div>
		</div >
	)
}