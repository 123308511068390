import Axios from 'axios';
import { RequestService } from '../utilities/request.component';
import EncryptService from './encrypt.service';

const API_URL = process.env.REACT_APP_API_URL
const API_TEST_URL = 'http://milanaextra.mob-tk.com'

export default class TestService {

    static async loginUnnax(token: any, solId: any) {
        var config: any = {
            method: 'get',
            url: `${API_URL}/api/backend-service/unnax/newRequest?solId=${solId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        return await Axios(config)
    }

    static async loginUnnaxTEST(phoneNumber: any, email: any, cli_id: any) {
        var data = JSON.stringify({ phoneNumber, email, cli_id });
        var config: any = {
            method: 'post',
            url: `${API_TEST_URL}/processrequest/clickr/newrequest`,
            headers: { 'Content-Type': 'application/json' },
            data
        };
        return await Axios(config)
    }

    static async getCards(id: any, token: any) {
        var config: any = {
            method: 'get',
            url: `${API_URL}/api/backend-service/unnax/cuentas/solicitud/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        return await Axios(config)
    }

    static async getCardsTEST(id: any) {
        var data = JSON.stringify({ "req_code": id });
        var config: any = {
            method: 'post',
            url: `${API_TEST_URL}/processrequest/clickr/gettokens`,
            headers: { 'Content-Type': 'application/json' },
            data
        };
        return await Axios(config)
    }

    static async sendToExecutive(id: any, token: any) {
        var config: any = {
            method: 'post',
            url: `${API_URL}/api/backend-service/unnax/tokens/solicitud/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        return await Axios(config)
    }

    static async sendToExecutiveTEST(id: any) {
        var data = JSON.stringify({ "req_code": id });
        var config: any = {
            method: 'post',
            url: `${API_TEST_URL}/processrequest/clickr/read`,
            headers: { 'Content-Type': 'application/json' },
            data
        };
        return await Axios(config)
    }

    static async requestSMS(phoneNumber: any, cliId: any, email: string, token: any) {
        var data = JSON.stringify({ "phoneNumber": phoneNumber, cliId, email });
        var config: any = {
            method: 'post',
            url: `${API_URL}/api/backend-service/sms/sendCode`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data
        };
        return await Axios(config)
    }

    static async requestLoginDev() {
        var config: any = {
            method: 'post',
            url: `${API_URL}/api/auth-service/auth/login`,
            headers: { 'Authorization': 'Basic cmljYXJkby52YXpxdWV6QGF4eGlzLmNvbS5teDpyaWNoYXJk' }
        };

        return await Axios(config)
    }

    static async verifyCode(smsToken: any, verifyCode: any, token: any) {
        var data = JSON.stringify({
            "reqCode": smsToken,
            "smsCode": verifyCode
        });

        var config: any = {
            method: 'post',
            url: `${API_URL}/api/backend-service/sms/verifyCode`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Axios(config)

    }

    static async getDesign(id: any, token: any) {
        var config: any = {
            method: 'get',
            url: `${API_URL}/api/backend-service/data/disenio/search/findByCliId?cliId=${id}`,
            headers: { 'Authorization': `Bearer ${token}` }
        };
        return await Axios(config)

    }

    static async deleteCard(id: any, token: any) {
        var config: any = {
            method: 'post',
            url: `${API_URL}/api/backend-service/unnax/cuentas/delete/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };
        return await Axios(config)
    }
}
