import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import authGuardService from '../service/authGuardService';
import TestService from '../service/testService';

export const LetsBegin = (props: any) => {

	const history = useHistory();
	const permisos: { label: any; }[] = []
	const design = authGuardService.getDesign()
	const cliId = authGuardService.getCliId()

	const [phone, setPhone] = useState<string>('')
	const [phoneValid, setPhoneValid] = useState<boolean>(true)
	const [email, setEmail] = useState<string>('')
	const [emailValid, setEmailValid] = useState<boolean>(true)
	const [isDisabled, setIsDisabled] = useState<boolean>(true)


	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso = { label: menuItem.label }
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	const validatePhone = (value: string) => {
		setPhoneValid(value.length == 10)
		setPhone(value)
		validateLogin()
	}

	const validateEmail = (value: string) => {
		setEmailValid(isValidEmail(value))
		setEmail(value)
		validateLogin()
	}

	const validateLogin = () => {
		if (phone.length > 0 && phoneValid && email.length > 0 && emailValid) setIsDisabled(false)
	}

	function isValidEmail(email: string) {
		return /\S+@\S+\.\S+/.test(email);
	}

	const requestLogin = async () => {
		authGuardService.setSolId('')
		authGuardService.sendKey(phone)
		authGuardService.sendEmail(email)
		await TestService.requestLoginDev()
			.then(async (responseLogin: any) => {
				await TestService.requestSMS(phone, cliId, email, responseLogin.data.access_token)
					.then(async (response) => {
						if (response.data.ok) {
							history.push({
								pathname: '/verifyNumber',
								state: { smsToken: response.data.mensaje }
							})
							authGuardService.setSolId(response.data.solId)
						}
					})
					.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
	}

	return (
		<div style={{ background: design.backgroundColor || '#5c39e9', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<div style={{ display: 'flex', justifyContent: 'center', background: design.backgroundColor || '#5c39e9', width: '100%', height: '20vh' }}>
				<div style={{
					display: 'flex', marginTop: '2rem', marginBottom: '2rem', marginRight: '2rem', marginLeft: '2rem', maxWidth: '80%', height: 'auto',
					justifyContent: design.align == 'centrado' ? 'center' : design.align == 'Centrado - Izquierda' ? 'left' : design.align == 'Centrado - Derecha' ? 'right' : 'center'
				}}>
					<img
						src={design.logo ? (design.logo == 'logo' ? `assets/layout/images/Algorithia-logoWhite.png` : design.logo) : `assets/layout/images/Algorithia-logoWhite.png`}
						className="login-logo"
						alt="login-logo"
						style={{ width: '60%', height: 'auto', display: 'flex', alignSelf: 'center' }}
					/>
				</div>

				{/* <img
					src={design.logo ? (design.logo == 'logo' ? `assets/layout/images/Algorithia-logoWhite.png` : design.logo) : `assets/layout/images/Algorithia-logoWhite.png`}
					className="login-logo"
					alt="login-logo"
					style={{ maxWidth: '60%', height: 'auto', display: 'flex', alignSelf: 'center', background: 'green' }}
				/> */}
			</div>
			<p style={{
				fontFamily: `${design?.typo || 'Roboto'}`,
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '24px',
				lineHeight: '32px',
				textAlign: 'center',
				color: design.textBackgroundColor ? design.textBackgroundColor : '#FFF'
			}}>¡Comencemos!</p>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#fff', height: '100%', width: '100%' }}>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '3rem' }}>

					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '20px',
						textAlign: 'center',
						color: '#666E70',
					}}>Ingresa tu número celular para continuar</p>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', width: '100%', maxWidth: '23rem' }}>
					<p style={{
						marginTop: 48,
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '20px',
						color: '#212426',
					}}>Celular a 10 dígitos</p>
					<span className="p-input-icon-left" style={{ width: '100%' }}>
						<i className="pi pi-mobile" style={{ color: design.backgroundColor || '#5c39e9' }} />
						<InputText
							placeholder="(_ _ _) _ _ _   _ _ _ _"
							className={!phoneValid ? "p-invalid block" : ''}
							style={{ width: '100%', borderColor: phoneValid ? 'transparent' : '', fontFamily: `${design?.typo || 'Roboto'}` }}
							onChange={(e) => validatePhone(e.target.value)}
							keyfilter={'int'}
							maxLength={10}
						/>
					</span>
					{
						!phoneValid &&
						<small id="username2-help" className="p-error block" style={{ marginTop: 5, fontFamily: `${design?.typo || 'Roboto'}` }}>
							<i className="pi pi-exclamation-circle" style={{ marginRight: 5 }} />
							Ingresa un celular válido
						</small>
					}
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', width: '100%', maxWidth: '23rem' }}>
					<p style={{
						marginTop: 24,
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '20px',
						color: '#212426',
					}}>Correo electrónico</p>
					<span className="p-input-icon-left" style={{ width: '100%' }}>
						<i className="pi pi-envelope" style={{ color: design.backgroundColor || '#5c39e9' }} />
						<InputText
							placeholder="micorreo@mail.com"
							style={{ width: '100%', borderColor: emailValid ? 'transparent' : '', fontFamily: `${design?.typo || 'Roboto'}` }}
							onChange={(e) => validateEmail(e.target.value)}
							className={!emailValid ? "p-invalid block" : ''}
						/>
					</span>
					{
						!emailValid &&
						<small id="username2-help" className="p-error block" style={{ marginTop: 5, fontFamily: `${design?.typo || 'Roboto'}` }}>
							<i className="pi pi-exclamation-circle" style={{ marginRight: 5 }} />
							Ingresa un correo válido
						</small>
					}
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '8vh', width: '100%', maxWidth: '25rem' }}>
					<p style={{
						fontFamily: `${design?.typo || 'Roboto'}`,
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '12px',
						lineHeight: '16px',
						textAlign: 'center',
						color: '#666E70'
					}}>Al hacer clic en continuar, estás aceptando nuestros</p>
					<div style={{ display: 'flex', justifyContent: 'row' }}>
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '12px',
							lineHeight: '16px',
							textAlign: 'center',
							color: design.textBackgroundColor ? design.textBackgroundColor : '#000000'
						}} onClick={() => history.push('/termsAndConditions')}>Términos y condiciones</p>
						&nbsp;<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '12px',
							lineHeight: '16px',
							textAlign: 'center',
							color: '#666E70'
						}}>y</p>&nbsp;
						<p style={{
							fontFamily: `${design?.typo || 'Roboto'}`,
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '12px',
							lineHeight: '16px',
							textAlign: 'center',
							color: design.textBackgroundColor ? design.textBackgroundColor : '#000000'
						}} onClick={() => history.push('/noticeOfPrivacy')}>Aviso de privacidad</p>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '4vh', marginBottom: '10vh', width: '90%' }}>
					<Button
						disabled={isDisabled}
						label='Continuar'
						style={{
							width: '100%',
							height: '52px',
							maxWidth: '25rem',
							borderRadius: '12px',
							color: design.textButtonColor || '#000',
							background: design.backgroundButtonColor || '#5c39e9',
							borderColor: 'transparent',
							fontFamily: `${design?.typo || 'Roboto'}`
						}}
						onClick={() => requestLogin()}
					/>
				</div>

			</div>
		</div >
	)
}