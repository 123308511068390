import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import loadingAnimation from '../assets/loading.json';

export const SendingInformation = (props: any) => {

	const history = useHistory();

	const checkOptions = {
		loop: false,
		autoplay: true,
		animationData: loadingAnimation,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	function navigateToNextScreen() {
		history.push({
			pathname: '/final-step',
			state: { cards: props.state.cards }
		})
	}

	setTimeout(navigateToNextScreen, 5000);

	return (
		<div style={{ background: '#eeeefa', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#eeeefa', height: '100vh', width: '90%', marginTop: '10vh' }}>
				<Lottie options={checkOptions}
					height={'15vh'}
					width={'15vh'}
				/>
				<p style={{
					fontFamily: 'Roboto',
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize: '24px',
					lineHeight: '32px',
					textAlign: 'center',
					color: '#212426',
					marginTop: 82
				}}>Enviando la información...</p>
				<p style={{
					fontFamily: 'Roboto',
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '17px',
					lineHeight: '24px',
					textAlign: 'center',
					color: '#313F4E',
					marginTop: 12
				}}>Esto puede tardar unos segundos.</p>

			</div>
		</div >
	)
}