import { User } from "../data/user";
import LocalService from "./local.service";

export default class authGuardService {
  static saveDesign(design: any) {
    if (design) LocalService.setJsonValue('design', design)
  }

  static sendUser(user: User) {
    if (user) {
      LocalService.setJsonValue('usuario', user);
    }
  }

  static sendPermits(permisos: any) {
    if (permisos) {
      LocalService.setJsonValue('permisos', permisos);
    }
  }

  static sendKey(key: string) {
    LocalService.setJsonValue("token", key)
  }

  static sendEmail(email: string) {
    LocalService.setJsonValue("email", email)
  }

  static sendReqCode(reqCode: string) {
    LocalService.setJsonValue("reqCode", reqCode)
  }

  static sendCliID(cliId: string) {
    LocalService.setJsonValue("cliId", cliId)
  }

  static getPermits() {
    return LocalService.getJsonValue('permisos');
  }

  static getUser() {
    return LocalService.getJsonValue('usuario');
  }
  static getToken() {
    return LocalService.getJsonValue('token');
  }

  static getEmail() {
    return LocalService.getJsonValue('email');
  }

  static getReqCode() {
    return LocalService.getJsonValue('reqCode');
  }

  static getDesign() {
    return LocalService.getJsonValue('design');
  }

  static getCliId() {
    return LocalService.getJsonValue('cliId');
  }

  static isLoggednIn() {
    return authGuardService.getUser() !== null;
  }
  static logout() {
    return LocalService.clearToken();
  }

  static setSolId(solId:string){
    LocalService.setJsonValue("solId", solId)
  }

  static getSolId(){
    return LocalService.getJsonValue('solId')
  }
}




